import React, { useState } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GlobalTop from "../../components/globalTop"
import ModalRegister from "../../components/modalRegister"

const Flebologia = () => {
  const [isRegisterOpen, setIsRegisterOpen] = useState(false)

  return (
    <Layout>
      <SEO
        seoTitle="Flebologia Kraków - gabinety lekarskie Omeda-Med"
        description="Flebolog stawia specjalistyczną diagnozę, która najczęściej jest wydawana na podstawie badania USG żył i tętnic kończyn dolnych."
      />

      <GlobalTop title="Kriochirurgia" offer />

      <section className="offer-single-content">
        <div className="container">
          <div className="row flex-column-reverse flex-md-row align-items-center">
            <div className="col-md-6">
              <h2 className="section--header">
                Czym jest
                <strong>kriochirurgia?</strong>
              </h2>
              <p>
                Pozwala na skuteczne usuwanie zmian skórnych. Polega na użyciu
                ciekłego azotu, którego temperatura wynosi -195 stopni
                Celsjusza, następnie miejsce jest “wymrażane”, a uszkodzona
                tkanka zostaje usunięta. Jedyne, co można odczuć po takim
                zabiegu, to szczypanie lub swędzenie – nie wymaga znieczulenia.
                Miejsce poddane leczeniu szybko się goi, a efekty są widoczne
                natychmiast.
              </p>
              <button
                className="btn-custom btn-custom--purple"
                onClick={() => setIsRegisterOpen(true)}
              >
                Umów się
              </button>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/kriochirurgia/kriochirurgia-image-1.png")}
                alt=""
              />
            </div>
          </div>
          <div className="divider"></div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/kriochirurgia/kriochirurgia-image-2.png")}
                alt=""
              />
            </div>
            <div className="col-md-5 offset-md-1">
              <h2 className="section--header">
                Co usuniesz taką
                <strong>metodą?</strong>
              </h2>
              <p>
                Istnieje kilka standardowych wskazań do zabiegu – sprawdź
                poniżej.
              </p>
              <ul>
                <li>Brodawki,</li>
                <li>Torbiele, kaszaki oraz prosaki,</li>
                <li>Odciski,</li>
                <li>Niektóre zmiany nowotworowe,</li>
                <li>Drobne blizny potrądzikowe,</li>
                <li>Włókniaki miękkie.</li>
              </ul>
            </div>
          </div>
          <div className="divider"></div>
          <div className="row flex-column-reverse flex-md-row align-items-center">
            <div className="col-md-6">
              <h2 className="section--header">
                Jakie efekty przynosi
                <strong>kriochirurgia?</strong>
              </h2>
              <p>
                Zabieg kriochirurgii polega na szybkim usunięciu i zastąpieniu
                tkanki na zdrową. Bezkrwawa metoda przekonuje coraz więcej osób
                – warto, ponieważ efekty są zadowalające. Jakie możemy wymienić?
              </p>
              <ul>
                <li>Szybkie gojenie się rany,</li>
                <li>Wygląd kosmetyczny,</li>
                <li>Brak poważnych skutków ubocznych,</li>
                <li>Minimalne ryzyko powikłań,</li>
                <li>Zabieg jest wykonywany bez względu na wiek.</li>
              </ul>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/kriochirurgia/kriochirurgia-image-3.png")}
                alt=""
              />
            </div>
          </div>
          <div className="divider"></div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/kriochirurgia/kriochirurgia-image-4.png")}
                alt=""
              />
            </div>
            <div className="col-md-5 offset-md-1">
              <h2 className="section--header">
                Jak przebiega zabieg
                <strong>kriochirurgii?</strong>
              </h2>
              <p>
                Zmiany na skórze eliminuje się poprzez zaaplikowanie na nie
                ciekłego azotu.{" "}
                <span className="purple-text">
                  Zabieg składa się z kilku cykli, a każdy trwa od kilku do
                  kilkunastu sekund.
                </span>{" "}
                Pomiędzy cyklami specjalista robi przerwę, aby brodawki
                całkowicie się odmroziły – daje to lepsze efekty terapeutyczne.
                Pacjent może odczuwać miejscowe zimno, szczypanie lub
                podrażnienie. Z czasem brodawka przekształca się w pęcherz,
                później wysycha, a na samym końcu tworzy się strup.
              </p>
            </div>
          </div>
          <div className="divider"></div>
          <div className="row align-items-center">
            <div className="col-md-6"></div>
            <div className="col-md-5 offset-md-1">
              <h2 className="section--header">
                Kilka wskazówek –
                <strong>przeczytaj przed zabiegiem kriochirurgii!</strong>
              </h2>
              <p>
                Często pacjenci przed zabiegiem zadają pytanie{" "}
                <span className="purple-text">
                  “czy muszę się specjalnie przygotować?”.
                </span>{" "}
                Tak naprawdę przed tą metodą leczenia nie jest wymagane żadne
                przygotowanie. Jednak{" "}
                <span className="purple-text">
                  warto pamiętać o zaleceniach po zabiegu kriochirurgii.
                </span>{" "}
                Pacjent powinien unikać sauny przynajmniej przez 14 dni.
                Solarium oraz działanie słońca może spowodować komplikacje, jak
                i gojenie rany będzie utrudnione. Dla lepszego efektu należy
                użyć kremów i maści przyspieszających regenerowanie się miejsca
                po zabiegu.
              </p>
              <button
                className="btn-custom btn-custom--purple"
                onClick={() => setIsRegisterOpen(true)}
              >
                Umów się
              </button>
            </div>
          </div>
        </div>
      </section>

      <ModalRegister
        isOpen={isRegisterOpen}
        onClose={() => setIsRegisterOpen(false)}
      />
    </Layout>
  )
}

export default Flebologia
